import Bandwidth from "./Bandwidth";

function PlanFree({ plan, max }) {
	return <>
		<div className="col">
			<div className="card mb-4 rounded-3 shadow-lg">
				<div className="card-header py-3">
					<h4 className="my-0 fw-normal m-0 mx-sm-5">{plan.Title}<br className="d-none d-sm-inline" /><br className="d-none d-sm-inline" /></h4>
				</div>
				<div className="card-body">
					<h1 className="card-title pricing-card-title">No Charge</h1>
					{/* <ul className="list-unstyled mt-3 mb-4"> */}
						{plan.Description}
					{/* </ul> */}
					<Bandwidth download={plan.Download} upload={plan.Upload} max={max} />
					<br />
					
					<button type="button" disable="true" className="w-100 btn btn-lg btn-outline-primary disabled">No Purchase Required</button>
				</div>
			</div>
		</div>
	</>
}

export default PlanFree