import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import queryString from 'query-string';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import * as Yup from 'yup';
import moment from 'moment';
import { BsCheck } from 'react-icons/bs';
import { usePlanQuery } from "../PlanQuery";
import api from "../Api";
import '../css/stripe.css';

const PurchaseSchema = Yup.object().shape({
	planId: Yup.number().required(),
	name: Yup.string().max(200, 'Name is too long').required('Required'),
	email: Yup.string().email('Invalid email').required('Required')
});

function PurchaseForm({ submitted, success, accessCode, emailAddress, purchaseError }) {
	const stripe = useStripe();
	const elements = useElements();
	const location = useLocation();
	const { executeRecaptcha } = useGoogleReCaptcha();
	
	const query = queryString.parse(location.search);
	const planId = query != null ? parseInt(query.plan ?? '0') : 0;

	const { data: plans, isLoading } = usePlanQuery();
	const [selectedPlan, setSelectedPlan] = useState(null);
	const [cardValid, setCardValid] = useState(false);

	const currency = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'symbol' });

	useEffect(() => {
		if (!isLoading && plans) {
			let plan = plans.find(p => p.Id === planId);
			setSelectedPlan(plan ?? plans[0]);
		}
	}, [plans, isLoading]);

	const planChanged = (planId) => {
		let index = plans.findIndex(p => p.Id === parseInt(planId));
		setSelectedPlan(plans[index]);
	}

	const cardChanged = (c) => {
		setCardValid(c.complete === true && c.error === undefined);
	}

	const handleSubmit = async (values) => {
		if (!executeRecaptcha) {
			console.log('recaptcha not loaded')
			// Recaptcha stripe has not loaded yet.
			return false;
		}
		// Block native form submission.
		// event.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not loaded yet. Make sure to disable
			// form submission until Stripe.js has loaded.
			return;
		}

		// Get a reference to a mounted CardElement. Elements knows how
		// to find your CardElement because there can only ever be one of
		// each type of element.
		const cardElement = elements.getElement(CardElement);

		// Use your card Element with other Stripe.js APIs
		const { error, paymentMethod } = await stripe.createPaymentMethod({
			type: 'card',
			card: cardElement,
			billing_details: {
				name: values.name,
				email: values.email
			}
		});

		if (error) {
			console.log('[error]', error);
			success(false);
			purchaseError('There was an error while attempting to process your payment');
		} else {
			console.log('[PaymentMethod]', paymentMethod);
			console.log(values)

			const recaptchaToken = await executeRecaptcha('form');

			try {
				let result = await purchase({ ...values, token: paymentMethod, recaptcha: recaptchaToken })
				success(result.success);
				if (result.success) {
					accessCode(result.accessCode);
					emailAddress(result.email);
				} else {
					purchaseError(result.error);
				}
				submitted(true);
			} catch (error) {
				purchaseError('There was an error while attempting to process your payment');
			}
		}
	}

	const purchase = async ({ planId, name, email, token, recaptcha }) => {
		if (planId == 0 || planId == undefined || planId == '') 
			planId = selectedPlan.Id;

		return await api.post('/purchase', { planId: planId, name: name, email: email, token: token, recaptcha: recaptcha })
			.then((response) => {
				return response.data;
			});
	}

	const getExpiry = () => {
		if (selectedPlan != null) {
			if (selectedPlan.ExpiryDate != null)
				return moment(selectedPlan.ExpiryDate).format('l LT');

			return moment().add(selectedPlan?.Duration ?? 0, 'minutes').format('l LT');
		}
		return null;
	}

	return <>
		<Formik
			initialValues={{ planId: planId, email: '', name: ''}}
			// isInitialValid={false}
			validateOnMount={true}
			validationSchema={PurchaseSchema}
			onSubmit={(values, { setSubmitting }) => {
				handleSubmit(values)
					.then(() => {
						setSubmitting(false);
					});
			}}
		>
			{({ isSubmitting, isValid, errors, handleChange }) => <>
				<Form className="form-signin">
					<div className="row">
						<div className="col-12 mb-3 mb-sm-2">
							<div>
								<p className="fw-bold mb-1">Plan</p>
								<Field name="planId" as="select" className="form-select shadow-sm" onChange={(e) => { planChanged(e.target.value); handleChange(e) }}>
									{!isLoading && plans && plans.filter(p => p.Cost > 0).map((plan, index) => {
										return <option key={index} value={plan.Id}>{plan.Title}</option>
									})}
								</Field>
								
							</div>
						</div>
						<div className="col-12 col-sm-4">
							<div>
								<p className="fw-bold mb-1">Cost</p>
								<p className="fs-4 fw-light lh-1 mb-1">{selectedPlan && currency.format(selectedPlan.Cost)}</p>
							</div>
						</div>
						<div className="col-12 col-sm-8">
							<div>
								<p className="fw-bold mb-1">Expiry</p>
								<p className="fs-4 fw-light lh-1 mb-1">{getExpiry()}</p>
							</div>
						</div>
					</div>
					<hr />
					<div className="row">
						<div className="col-12 col-sm-6 mb-3 form-floating-x">
							<p className="fw-bold mb-1">Name</p>
							<Field id="name" type="text" name="name" className={`form-control shadow-sm ${errors.name && 'is-invalid'}`} placeholder="Required" />
							<ErrorMessage name="name" component="div" className="fw-bold small text-danger" />
						</div>
						<div className="col-12 col-sm-6 mb-3 form-floating-x">
							<p className="fw-bold mb-1">Email Address</p>
							<Field id="email" type="email" name="email" className={`form-control shadow-sm ${errors.email && 'is-invalid'}`}  placeholder="Required" />
							<ErrorMessage name="email" component="div" className="fw-bold small text-danger" />
						</div>
					</div>

					<p className="fw-bold mb-1">Credit Card Information</p>
					<CardElement onChange={cardChanged} />
					<p className="small">Credit card processing is powered by <a href="https://www.stripe.com" target="_blank" rel="noreferrer">Stripe</a></p>
					<button className="btn btn-lg btn-primary w-100" type="submit" disabled={!stripe || isSubmitting || !isValid || !cardValid}>
						{!isSubmitting ? <><BsCheck /> Complete Purchase</> : <>Processing Payment...</>}
					</button>
				</Form>
			</>}
		</Formik>
	</>
}

export default PurchaseForm;