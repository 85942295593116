import Devices from "./Devices";
import Bandwidth from "./Bandwidth";

function Plan({ plan, selectPlan }) {
	const currency = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'symbol' });

	return <>
		<div className="col">
			<div className="card mb-4 rounded-3 shadow-lg">
				<div className="card-header py-3">
					<h4 className="my-0 fw-normal m-0 mx-sm-5">{plan.Title}</h4>
				</div>
				<div className="card-body">
					<h1 className="card-title pricing-card-title">{currency.format(plan.Cost)}</h1>
					{/* <ul className="list-unstyled mt-3 mb-4"> */}
						{plan.Description}
					{/* </ul> */}
					<Devices count={plan.DeviceLimit} />
					<Bandwidth download={plan.Download} upload={plan.Upload} />
					<br />
					
					<button onClick={() => selectPlan(plan)} type="button" className="w-100 btn btn-lg btn-primary">Purchase</button>
				</div>
			</div>
		</div>
	</>
}

export default Plan