import './Bandwidth.css'

function Bandwidth({ download, upload, max }) {

	let total = Math.max(parseInt(download), parseInt(upload));
	if (!max)
		max = total;
	else
		total = max;

	return <div className="bandwidth">
		<div className="small">Download speeds up to <span className="fw-bold">{download} Mbps</span></div>
		<div className="progress">
			<div className="progress-bar" role="progressbar" style={{width: (download / total * 100) + '%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax={total}></div>
		</div>
		<div className="small">Upload speeds up to <span className="fw-bold">{upload} Mbps</span></div>
		<div className="progress">
			<div className="progress-bar" role="progressbar" style={{width: (upload / total * 100) + '%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax={total}></div>
		</div>
	</div>
}

export default Bandwidth