import { useEffect, useState } from "react";
import { Switch, Route, NavLink, useLocation } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import * as Sentry from "@sentry/react";
import './Cover.css';

import Index from "./pages/Index";
import Pricing from "./pages/Pricing";
import Purchase from "./pages/Purchase";
import SignIn from "./pages/SignIn";

if (window._env_.sentryEnabled === "true") {
	Sentry.init({
		dsn: window._env_.sentryDsn,
		environment: window._env_.sentryEnv,
	
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 0.25,
	});
}

const queryClient = new QueryClient();

function App() {
	const location = useLocation();
	const [appTitle, setAppTitle] = useState('');

	useEffect(() => {
		setAppTitle(window._env_.title);
		document.title = window._env_.title;
	 }, []);

	return <>
		<QueryClientProvider client={queryClient}>
		<header className="mb-3 mb-sm-5">
			<div>
				<h3 className="float-md-start mb-0 fw-bold d-none d-sm-block">{appTitle}</h3>
				<nav className="nav nav-masthead justify-content-center float-md-end">
					<NavLink to={`/${location.search}`} exact className="nav-link" activeClassName="active" aria-current="page">Home</NavLink>
					<NavLink to={`/signin${location.search}`} exact className="nav-link" activeClassName="active">Sign In</NavLink>
					<NavLink to={`/pricing${location.search}`} className="nav-link" activeClassName="active">Pricing</NavLink>
					<NavLink to={`/purchase${location.search}`} className="nav-link" activeClassName="active">Purchase</NavLink>
				</nav>
			</div>
		</header>

		<main className="pb-4">		
			<Switch>
				<Route path="/" exact component={Index} />
				<Route path="/signin" component={SignIn} />
				<Route path="/pricing" component={Pricing} />
				<Route path="/purchase" component={Purchase} />
			</Switch>
		</main>

		{/* <footer className="mt-auto text-black-50">
			<p>Cover template for <a href="https://getbootstrap.com/" className="text-black">Bootstrap</a>, by <a href="https://twitter.com/mdo" className="text-black">@mdo</a>.</p>
		</footer> */}
		</QueryClientProvider>
	</>
}

export default App;
