import { BsPhone, BsTablet, BsLaptop } from 'react-icons/bs';
import './Devices.css'

function Devices({ count }) {
	if (count === 0)
		return <></>

	return <div className="devices">
		<div className="small" style={{marginBottom: '5px'}}>Access Code can be used to authorize up to {count} devices.</div>
		{count > 0 &&
			<BsPhone size="20" />
		}
		{count > 3 && 
			<BsPhone size="20" style={{marginLeft: '5px'}} />
		}
		{count > 1 && 
		<BsTablet size="30" style={{marginLeft: '5px'}} />
		}
		{count > 4 && 
		<BsTablet size="30" style={{marginLeft: '5px'}} />
		}
		{count > 2 && 
		<BsLaptop size="30" style={{marginLeft: '5px'}} />
		}
		{count > 5 && 
		<BsLaptop size="30" style={{marginLeft: '5px'}} />
		}
	</div>
}

export default Devices