import { useHistory, useLocation } from "react-router-dom";
import queryString from 'query-string';
import { usePlanQuery } from "../PlanQuery";
import Plan from "../components/Plan";
import PlanFree from "../components/PlanFree";

function Pricing() {
	const history = useHistory();
	const location = useLocation();
	const { data: plans, isLoading } = usePlanQuery();

	const getMaximumSpeed = () => {
		if (!plans || plans.length === 0)
			return 0;

		var max = 0;
		for (let p of plans) {
			if (p.Download > max)
				max = p.Download;
			if (p.Upload > max)
				max = p.Upload;
		}
		return max;
	}

	const selectPlan = (plan) => {
		var query = queryString.stringify({ ...queryString.parse(location.search), plan: plan.Id });
		history.push(`/purchase?${query}`)
	}

	return <>

		<div className="pricing-header p-3 pb-md-4 mx-auto text-center">
			<h1 className="display-5 fw-normal">Pricing</h1>
			<p className="fs-5 mb-0">Choose from a variety of options to meet your needs.</p>
		</div>

		<div className="row row-cols-1 row-cols-md-2 mb-3 text-center">
			{!isLoading && plans && plans.map((plan, index) => {
				return (
					plan.Cost > 0 ? <Plan plan={plan} key={index} selectPlan={() => selectPlan(plan)} /> : <PlanFree plan={plan} max={getMaximumSpeed()} key={index} />
				)
			})}
		</div>
	</>
}

export default Pricing