import { BsCheckCircle } from 'react-icons/bs';
import './ConnectedToNetwork.css'

function ConnectedToNetwork({ network }) {
	return <>
		<div className="connected">
			<BsCheckCircle color="green" /> You are connected to the <span>{network}</span> Wi-Fi Network
		</div>

	</>
}

export default ConnectedToNetwork