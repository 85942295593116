import { useState } from 'react';
import { useLocation, Link } from "react-router-dom";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { BsCheckCircle, BsXCircle } from 'react-icons/bs';
import PurchaseForm from '../components/PurchaseForm';

function Purchase() {
	const location = useLocation();

	const stripePromise = loadStripe(window._env_.stripeKey);
	const [purchaseSubmitted, setPurchaseSubmitted] = useState(false);
	const [paymentSuccess, setPaymentSuccess] = useState(false);
	const [accessCode, setAccessCode] = useState('');
	const [emailAddress, setEmailAddress] = useState('');
	const [purchaseError, setPurchaseError] = useState(null);

	return <>
		<div className="pricing-header p-3 pb-md-4 mx-auto text-center">
			<h1 className="display-5 fw-normal">Purchase Wi-Fi Access</h1>
			<p className="fs-5 mb-0">Complete your purchase via secure online payment.</p>
		</div>

		<div className="card mx-auto" style={{maxWidth: '30em'}}>
			<div className="card-header">
				<h4>Purchase {!paymentSuccess ? 'Details' : 'Complete'}</h4>
			</div>
			<div className="card-body" style={{textAlign: 'left'}}>
					
				<Elements stripe={stripePromise}>
					{!purchaseSubmitted &&
						<PurchaseForm submitted={setPurchaseSubmitted} success={setPaymentSuccess} accessCode={setAccessCode} emailAddress={setEmailAddress} purchaseError={setPurchaseError} />
					}
					{paymentSuccess && 
						<div style={{textAlign: 'center'}}>

							<BsCheckCircle size="100" color="green" />
							<p>Payment Successfully Completed</p>

							<div className="mb-3">
								<div className="fw-bold">Your access code is:</div>
								<div className="fs-1 fw-normal font-monospace text-primary">{accessCode}</div>
								An email with your purchase details has been sent to: <strong>{emailAddress}</strong>
							</div>

							<Link to={`/signin?accessCode=${accessCode}`} className="btn btn-lg btn-success w-100">Continue to Sign In</Link>

						</div>
					}
					{purchaseError &&
						<div style={{textAlign: 'center', marginTop: '15px'}}>
							<BsXCircle size="100" color="red" />
							<p>Payment Failed</p>
							<div className="mb-3">
								<div className="fw-bold">The returned error message was:</div>
								{purchaseError}
							</div>
							<button className="btn btn-secondary text-white btn-lg w-100" type="button" onClick={() => { setPurchaseSubmitted(false); setPurchaseError(null) }}>
								Try Again
							</button>
						</div>
					}
				</Elements>

			</div>
		</div>
	</>
}

export default Purchase;