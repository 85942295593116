import { BsWifi } from 'react-icons/bs';
import './NotConnectedToNetwork.css'

function NotConnectedToNetwork({ network }) {
	return <>
		<div className="not-connected">
			<BsWifi size="100" className="icon" />
			<h4>You are not connected to the <span className="fw-bold">{network}</span> network.</h4>
			<div>
				Please connect to the <span className="fw-bold" >{network}</span> Wi-Fi network to sign in with your access code.
			</div>
		</div>

	</>
}

export default NotConnectedToNetwork