import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import queryString from 'query-string';
import { BsCheck, BsCheckCircle, BsXCircle, BsWifi } from 'react-icons/bs';
import Api from "../Api";
import NotConnectedToNetwork from "../components/NotConnectedToNetwork";
import ConnectedToNetwork from "../components/ConnectedToNetwork";

const SignInSchema = Yup.object().shape({
	accessCode: Yup.string().min(8, 'Must be 8 digits').max(8, 'Must be 8 digits').required('Required')
});

function SignIn() {
	const [networkName, setNetworkName] = useState('');
	const [isGuestNetwork, setIsGuestNetwork] = useState(undefined);
	const [isSignedIn, setIsSignedIn] = useState(false);
	const [signInComplete, setSignInComplete] = useState(false);
	const [signInFailed, setSignInFailed] = useState(false);
	const [signInError, setSignInError] = useState(null);
	const [devicesRemaining, setDevicesRemaining] = useState(null);

	const location = useLocation();
	
	const query = queryString.parse(location.search);
	const accessCode = query != null && query.accessCode != null ? (!isNaN(query.accessCode) ? parseInt(query.accessCode ?? 0) : '') : '';

	useEffect(() => {
		setNetworkName(window._env_.network);

		async function fetchNetworkStatus() {
			try {
				let networkStatus = await Api.get('/client', { cache: { ignoreCache: true } });
				setIsGuestNetwork(networkStatus.data.success);
				setIsSignedIn(networkStatus.data.signed_in);
			} catch {
				setIsGuestNetwork(false);
			}
		}
		fetchNetworkStatus();
	}, [])

	const handleSubmit = async (values) => {
		let result = await Api.post('/authorize', { access_code: values.accessCode });

		if (result.data.success) {
			setSignInComplete(true);
			setDevicesRemaining(result.data.devicesRemaining)
		} else {
			setSignInFailed(true);
			setSignInError(result.data.error);
		}

		// requestAuthorization(values.accessCode)
		// 	.then(result => {
		// 		if (result.data.success) {
		// 			setSignInComplete(true);
		// 			setDevicesRemaining(result.data.devicesRemaining)
		// 		} else {
		// 			setSignInFailed(true);
		// 			setSignInError(result.data.error);
		// 		}
		// 	});
	}

	// const requestAuthorization = async (accessCode) => {
	// 	return await Api.post('/authorize', { access_code: accessCode })
	// }	

	const handleKeyDown = (e) => {
		// console.log(e)

		if (e.keyCode === 65 && e.metaKey === true) // cmd/ctrl + a combination
			return;

		if ((e.keyCode !== 8 && e.keyCode !== 13 && e.keyCode !== 37 && e.keyCode !== 39 && e.target.value.length === 8)) { // only allow backspace
			e.preventDefault();
			e.stopPropagation();
		}

		if (e.keyCode === 86 && e.metaKey === true) // cmd/ctrl + v combination
			return;

		if (e.keyCode === 13 || e.keyCode === 37 || e.keyCode === 39) // enter key, left & right arrow keys
			return;
		
		if (e.keyCode !== 8 && (e.keyCode < 48 || e.keyCode > 57 || e.keyCode === 189 || e.shiftKey === true || e.altKey === true)) {
			e.preventDefault();
			e.stopPropagation();
		}
	}

	const signOut = () => {
		setIsSignedIn(false)

		Api.post('/unauthorize')
	}

	// const handleKeyUp = (e) => {
	// 	if (e.target.id === 'accessCode1' && e.target.value.length >= 4) {
	// 		accessCode2Element.current.focus();
	// 	} else if (e.target.id === 'accessCode2' && (e.target.value.length === 0)) {
	// 		accessCode1Element.current.focus();
	// 	}
	// }

	return <>
		<div className="pricing-header p-3 pb-md-4 mx-auto text-center">
			<h1 className="display-5 fw-normal">Sign In</h1>
			<p className="fs-5 mb-0">Access high-speed wireless network across the MBC resort and campground.</p>
		</div>

		<div className="card mx-auto" style={{maxWidth: '23em'}}>
			<div className="card-header">
				<h4>{ isSignedIn ? "Signed In" : "Sign In" }</h4>
			</div>
			<div className="card-body">
				{isGuestNetwork === undefined && 
					<>
						<BsWifi size="100" className="icon" color="deepskyblue" />
						<div>Checking network status...</div>
					</>
				}
				{isGuestNetwork === true && 
					<ConnectedToNetwork network={networkName} />
				}
				{isGuestNetwork === false && 
					<NotConnectedToNetwork network={networkName} />
				}

				{isSignedIn === true && 
					<div>
						<p className="fw-bold fs-4">You are signed in already</p>

						<p>If you sign out, you can use your access code again on another device.</p>

						<button onClick={signOut} className="btn btn-primary btn-lg d-block w-100 mt-2" type="button">
							Sign Out
						</button>
					</div>
				}
				
				{!signInComplete && !signInFailed && !isSignedIn && isGuestNetwork === true &&
					<Formik
						initialValues={{ accessCode: accessCode }}
						validationSchema={SignInSchema}
						onSubmit={(values, { setSubmitting }) => {
							handleSubmit(values)
								.then(() => {
									setSubmitting(false);
								});
						}}
					>
						{({ isSubmitting, isValid, values, errors, touched }) =>
							<Form className="form-signin">
								
								<div className="row">
									<div className="col-12">
										<p className="fw-bold">Sign In with your Access Code</p>
									</div>
									<div className="col mb-3 form-floating-x">
										<Field id="accessCode" type="text" name="accessCode" className={`form-control fs-1 font-monospace text-center ${errors.accessCode && 'is-invalid'} ${!errors.accessCode && touched.accessCode && 'is-valid'}`} placeholder="Code" onKeyDown={handleKeyDown} />
										<ErrorMessage name="accessCode" component="div" className="fw-bold small text-danger" />
									</div>
								</div>

								<button className="btn btn-primary btn-lg d-block w-100 mt-2" type="submit" disabled={isSubmitting || !isValid || values.accessCode === ''}>
									{!isSubmitting ? <><BsCheck /> Sign In</> : <>Signing In...</>}
								</button>
							</Form>
						}
					</Formik>
				}
				{signInComplete &&
					<div style={{textAlign: 'center'}}>
						<BsCheckCircle size="100" color="green" />
						<p className="fw-bold">Sign In Successful</p>
						{devicesRemaining > 0 &&
							<p>You can sign in with this access code on up to {devicesRemaining} more device(s)</p>
						}
						{devicesRemaining === 0 &&
							<p>You can't sign in with this access code on any more devices.</p>
						}
					</div>
				}
				{signInFailed &&
					<div style={{textAlign: 'center'}}>
						<BsXCircle size="100" color="red" />
						<p className="fw-bold">Sign In Failed</p>
						<p>{signInError}</p>						
						<button className="btn btn-secondary text-white btn-lg w-100" type="button" onClick={() => setSignInFailed(false)}>
							Try Again
						</button>
					</div>
				}
			</div>
		</div>
	</>
}

export default SignIn