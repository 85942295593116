
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom"
import logo from '../images/logo.png';

function Index() {
	const location = useLocation();
	const [appTitle, setAppTitle] = useState('');

	useEffect(() => {
		setAppTitle(window._env_.title);
	 }, []);

	return <>

		<div className="pricing-header p-3 pb-md-4 mx-auto text-center">
			<img src={logo} alt={appTitle} size="100" className="logo" />
			<h1 className="display-5 fw-normal">Welcome to {appTitle}</h1>
			<p className="fs-5">Access high-speed wireless internet across the MBC resort and campground.</p>
			<p className="lead">
				<Link to={`/signin${location.search}`} className="btn btn-lg btn-primary fw-bold me-sm-3 me-0 mb-3 mb-sm-0 shadow btn-welcome">Sign In</Link>
				<Link to={`/pricing${location.search}`} className="btn btn-lg btn-secondary fw-bold border-white bg-white shadow btn-welcome">See Pricing</Link>
			</p>
		</div>

	</>
}

export default Index